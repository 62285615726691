<template>
  <el-dialog
    visible
    append-to-body
    :show-close="false"
    :close-on-click-modal="false"
    custom-class="rounded p-4 mt-5 mb-0 response-modal"
    @close="$emit('close')"
  >
    <template #title>
      <div class="d-flex justify-content-between pb-4">
        <div class="d-flex align-items-center gap-2">
          <h2>{{ translate('titles.balanceAlignment', { date }) }}</h2>
          <Tag v-if="transformedRequest.closed">{{ $t('requestsSupplier.table.requestStatus.closed') }}</Tag>
          <Tag v-else-if="isReviewRequired" type="danger">{{
            $t('requestsSupplier.table.requestStatus.reviewRequired')
          }}</Tag>
          <Tag v-else-if="transformedRequest.isAwaitingResponse">{{
            $t('requestsSupplier.table.requestStatus.open')
          }}</Tag>
          <Tag v-else type="info">{{ $t('requestsSupplier.table.requestStatus.inReview') }}</Tag>
        </div>
        <Button type="icon" class="p-0 text-typography-primary action-btn" @click="$emit('close')">
          <CloseIcon />
        </Button>
      </div>
    </template>
    <div :loading="requesterLoading && responderLoading">
      <section class="mb-5">
        <Details :request="transformedRequest" :requester="requester" />
      </section>
      <main class="pb-4">
        <Button
          v-if="isReviewRequired"
          type="link"
          class="text-typography-primary mb-4 p-0"
          @click="isOriginalRequestShown = !isOriginalRequestShown"
        >
          <span class="text-decoration-underline">{{
            translate(isOriginalRequestShown ? 'hide' : 'showOriginalRequest')
          }}</span>
          <ChevronUpIcon v-if="isOriginalRequestShown" :size="16" /><ChevronDownIcon v-else :size="16" />
        </Button>
        <div
          v-if="!isReviewRequired || (isReviewRequired && isOriginalRequestShown)"
          :class="{ 'is-in-review-box': isReviewRequired }"
        >
          <div
            v-if="!transformedRequest.isAwaitingResponse || isReviewRequired"
            class="d-flex mb-2 gap-2 align-items-center"
          >
            <RequestIcon :size="16" />
            <el-tooltip placement="top" :content="getTooltipContentRequest(transformedRequest)">
              <span class="fw-bold lh-1">{{ $t('requestsSupplier.responseModal.requestWasOpenedByClarity') }}</span>
            </el-tooltip>
          </div>
          <div :class="{ 'px-5': !transformedRequest.isAwaitingResponse || isReviewRequired }">
            <p
              :class="transformedRequest.isAwaitingResponse && !isReviewRequired ? 'fw-bold' : 'fw-normal'"
              class="mb-2"
            >
              {{ translate('uploadFiles', { requester: requester?.name }) }}
            </p>
            <ul class="mb-3 px-4">
              <li>{{ translate('RS', { date }) }}</li>
            </ul>
          </div>
          <div v-if="(!transformedRequest.isAwaitingResponse && transformedRequest.responses) || isReviewRequired">
            <div
              v-for="(response, responseIndex) in transformedRequest.responses"
              :key="responseIndex"
              class="gap-3 my-5"
            >
              <div v-if="!response.isCreatedByAdmin || isAdmin">
                <div class="d-flex gap-2 mb-3 align-items-center">
                  <ReplyIcon fill="#9295A5" :size="16" />
                  <el-tooltip placement="top" :content="getTooltipContentResponse(transformedRequest, responseIndex)">
                    <span class="fw-bold lh-1">
                      {{
                        $t('requests.responseFrom', {
                          responder: response.isCreatedByAdmin ? 'Clarity' : responder.name,
                        })
                      }}
                    </span>
                  </el-tooltip>
                </div>
                <div v-if="response.attachments" class="d-flex mb-5 mx-4 flex-wrap gap-2">
                  <div
                    v-for="(filename, attachmentIndex) in response.attachments.map((path) => path.split('/').pop())"
                    :key="attachmentIndex"
                    class="attachment d-flex align-items-center gap-2"
                    role="button"
                    @click="onFileClick(transformedRequest, responseIndex, attachmentIndex)"
                  >
                    <PDFIcon :size="16" />
                    <span class="fw-normal mr-2" style="font-size: 12px">{{ filename }}</span>
                  </div>
                </div>
                <p v-if="response.text" class="mx-4 px-1 mb-5">
                  {{ transformedRequest.responses[responseIndex].text }}
                </p>
                <div
                  v-if="
                    transformedRequest.responses[responseIndex].reject?.text &&
                    (!isReviewRequired || responseIndex !== transformedRequest.responses.length - 1)
                  "
                  class="d-flex flex-column gap-2"
                >
                  <div class="d-flex gap-2 align-items-center">
                    <NoticeFullIcon fill="#E52044" :size="16" />
                    <p class="fw-bold">{{ $t('requests.invalidResponseFeedback') }}</p>
                  </div>
                  <p class="px-5">{{ transformedRequest.responses[responseIndex].reject.text }}</p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!transformedRequest.isAwaitingResponse && !transformedRequest.closed">
            <NoticeFullIcon fill="#306FEB" :size="16" />
            <span class="mx-1" style="color: #306feb">
              {{ $t('requests.responseIsBeingChecked') }}
            </span>
          </div>
          <div v-if="transformedRequest.closed" class="d-flex flex-column">
            <div class="d-flex gap-2 align-items-center">
              <CheckIcon fill="#11874F" :size="16" />
              <p>{{ $t('requests.requestIsClosed') }}</p>
            </div>
            <p class="mt-2 me-5">{{ formatDateWithTime(transformedRequest.closedAt) }}</p>
          </div>
        </div>
        <div v-if="isReviewRequired" class="d-flex flex-column gap-4 mb-5">
          <div class="d-flex gap-2 text-danger align-items-center">
            <NoticeFullIcon fill="#E52044" :size="16" />
            <p>{{ $t('requests.invalidResponseFeedback') }}</p>
          </div>
          <p>{{ transformedRequest.responses.at(-1).reject.text }}</p>
        </div>
        <el-upload
          v-if="transformedRequest.isAwaitingResponse && !transformedRequest.closed"
          ref="uploadRef"
          class="upload-file d-flex flex-column gap-4"
          action="#"
          multiple
          drag
          :http-request="uploadFile"
          :on-success="onUploadSuccess"
          :on-error="handleFileUploadFailure"
        >
          <div>
            <div class="text-info">
              <UploadIcon width="50px" height="50px" />
            </div>
            <div class="el-upload__text">
              {{ translate('uploadFile.dropText') }}
              <em>{{ translate('uploadFile.clickText') }}</em>
            </div>
          </div>
          <template #file="{ file }">
            <div class="uploaded-file py-2 px-3 d-flex justify-content-between mb-3">
              <div class="d-flex gap-2">
                <div v-if="file.status !== 'success'" class="upload-loading">
                  <div :loading="true" />
                </div>
                <CheckCircleFullIcon v-else class="text-success" />
                <Button
                  type="link"
                  class="p-0 text-typography-primary file-name"
                  :class="{ 'text-typography-secondary': file.status !== 'success' }"
                  @click="showFile(file)"
                >
                  <div class="text-truncate text-start">{{ file.name }}</div>
                </Button>
              </div>
              <el-tooltip :content="translate('removeDocument')" placement="top">
                <Button type="icon" class="p-0 remove-file-icon" @click="handleRemove(file)">
                  <TrashCanIcon v-if="file.status === 'success'" :size="16" />
                </Button>
              </el-tooltip>
            </div>
          </template>
        </el-upload>
        <p v-if="hasFailedValidation" class="text-danger">{{ translate('noFiles') }}</p>
        <el-dialog append-to-body :visible.sync="dialogVisible">
          <iframe :src="currentFileUrl" style="width: 100%; height: 50vh"></iframe>
        </el-dialog>
      </main>
    </div>
    <template v-if="transformedRequest.isAwaitingResponse && !transformedRequest.closed" #footer>
      <div class="pt-4">
        <Button type="secondary" @click="$emit('close')">{{ $t('commons.cancel') }}</Button>
        <Button type="primary" :loading="submitLoading" @click="onSubmit">{{ $t('commons.save') }}</Button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { computed, watch, ref, getCurrentInstance, toRefs } from 'vue';
import { DateTime } from 'luxon';

import { useUser } from '@/modules/auth';
import { Tag, Button } from '@/modules/core';
import {
  CloseIcon,
  UploadIcon,
  TrashCanIcon,
  CheckCircleFullIcon,
  RequestIcon,
  ReplyIcon,
  PDFIcon,
  NoticeFullIcon,
  CheckIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from '@/assets/icons';
import { options } from '@/locale/dateConfig';
import { useBusinessById } from '@/modules/business/compositions/business';
import { ROLE_TYPE } from '@/modules/users/constants';

import Details from './components/Details';
import { getResponseFileUpload, useCreateResponse, USER_QUERY } from './compositions';
import { convertImageToPdf } from './pdfConverter';

export default {
  components: {
    Tag,
    Button,
    CloseIcon,
    Details,
    UploadIcon,
    TrashCanIcon,
    CheckCircleFullIcon,
    RequestIcon,
    ReplyIcon,
    PDFIcon,
    NoticeFullIcon,
    CheckIcon,
    ChevronUpIcon,
    ChevronDownIcon,
  },
  props: {
    request: { type: Object, required: true },
  },
  setup(props) {
    const {
      proxy: root,
      proxy: { $i18n },
    } = getCurrentInstance();

    const formatDate = (isoDate) => isoDate && new Date(isoDate).toLocaleDateString($i18n.locale, options.short);
    const { isAdmin } = useUser();

    const { business: requester, loading: requesterLoading } = useBusinessById(
      computed(() => ({ businessId: props.request.sourceBusinessId }))
    );

    const { business: responder, loading: responderLoading } = useBusinessById(
      computed(() => ({ businessId: props.request.targetBusinessId }))
    );

    const { request } = toRefs(props);
    const transformedRequest = ref({ ...props.request });

    watch(
      request,
      async (request) => {
        await Promise.all(
          (request.responses ?? []).map((response) =>
            root.$apollo.query({ query: USER_QUERY, variables: { id: response.createdBy.id } }).then(({ data }) => ({
              ...response,
              isCreatedByAdmin: data.user.memberships.some(({ role }) => role === ROLE_TYPE.ADMIN),
            }))
          )
        ).then((data) => (transformedRequest.value = { ...request, responses: data }));
      },
      { immediate: true, deep: true }
    );

    const { mutate: createResponse } = useCreateResponse();
    return {
      requesterLoading,
      responderLoading,
      requestOpenedAgo: DateTime.fromMillis(props.request.createdAt).toRelative(),
      date: formatDate(props.request.templateData.balanceAlignment.reconciliationDate),
      requester,
      responder,
      translate: (key, params) => $i18n.t(`requestsSupplier.responseModal.${key}`, params),
      currentFileUrl: ref(null),
      dialogVisible: ref(false),
      uploadRef: ref(null),
      fileList: ref([]),
      hasFailedValidation: ref(false),
      submitLoading: ref(false),
      createResponse,
      transformedRequest,
      isAdmin,
      isReviewRequired: computed(
        () => !props.request.closed && props.request.isAwaitingResponse && props.request.responses?.at(-1)?.reject
      ),
      isOriginalRequestShown: ref(false),
    };
  },
  methods: {
    async uploadFile(request) {
      this.hasFailedValidation = false;
      try {
        const pdfFile = this.isNotPdfFile(request.file.type) ? await convertImageToPdf(request.file) : request.file;
        const { url, filePath, fields } = await getResponseFileUpload({
          filename: pdfFile.name,
          requestId: this.request.id,
        });
        const parsedFields = JSON.parse(fields);
        const formData = new FormData();
        Object.keys(parsedFields).forEach((key) => formData.append(key, parsedFields[key]));
        formData.append('file', pdfFile);

        const response = await fetch(url, { method: 'POST', body: formData });
        if (response.ok) request.onSuccess(filePath);
      } catch (err) {
        this.$message.error(`${err.error} - ${err.reason}`);
      }
    },
    isNotPdfFile(fileType) {
      return ['png', 'jpg', 'jpeg'].includes(fileType.split('/').pop().toLowerCase());
    },
    onUploadSuccess(filePath, file) {
      if (!filePath) return;
      this.fileList.push({ uid: file.uid, name: file.name, filePath });
    },
    handleRemove(file) {
      const uploadComponent = this.uploadRef;
      const uploadedFiles = uploadComponent.uploadFiles;
      const uploadedFileIndex = uploadedFiles.findIndex((f) => f.uid === file.uid);
      if (uploadedFileIndex !== -1) {
        uploadedFiles.splice(uploadedFileIndex, 1);
      }
      const fileListIndex = this.fileList.findIndex(({ uid }) => uid === file.uid);
      if (fileListIndex !== -1) {
        this.fileList.splice(fileListIndex, 1);
      }
    },
    showFile(file) {
      this.currentFileUrl = URL.createObjectURL(file.raw);
      this.dialogVisible = true;
    },
    async onSubmit() {
      this.submitLoading = true;
      if (!this.fileList.length) {
        this.hasFailedValidation = true;
        this.submitLoading = false;
        return;
      }
      try {
        await this.createResponse({
          requestId: this.request.id,
          createParams: { attachments: this.fileList.map(({ filePath }) => filePath) },
        });
        this.$emit('close');
        this.$message.success(this.translate('submittedForReview'));
      } catch {
        this.$message.error(this.$t('commons.messages.action.error'));
      }
      this.submitLoading = false;
    },
    handleFileUploadFailure() {
      this.$message.error(this.$t('commons.messages.action.error'));
    },
    getTooltipContentRequest(request) {
      return DateTime.fromMillis(request.createdAt).toFormat('HH:mm ,dd.MM.yy');
    },
    getTooltipContentResponse(request, responseIndex) {
      const response = request.responses[responseIndex];
      return DateTime.fromMillis(response.createdAt).toFormat('HH:mm ,dd.MM.yy');
    },
    onFileClick(request, responseIndex, attachmentIndex) {
      this.currentFileUrl = request.responses[responseIndex].filePathUrls[attachmentIndex];
      this.dialogVisible = true;
    },
    formatDateWithTime(date) {
      return DateTime.fromMillis(date).toFormat('HH:mm ,dd.MM.yy');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

$modal-height: 90vh;

:deep(.response-modal) {
  max-height: $modal-height;
  width: 50rem;
  overflow: hidden;
  margin-top: 3rem !important;
  display: grid;
  grid-template-rows: auto 1fr auto;

  .el-dialog__header,
  .el-dialog__body,
  .el-dialog__footer {
    padding: 0;
    color: $typography-primary;
    overflow: hidden;
  }
  main {
    max-height: calc(#{$modal-height} - 17.5rem);
    overflow-x: auto;
  }
  .height-24 {
    height: 1.5rem;
  }
  .upload-file {
    width: 25rem;
  }
  .el-upload-dragger {
    width: 100%;
    height: 6.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .el-upload-list__item,
  .el-upload-list__item:last-child {
    margin: 0;
  }
  .el-upload-list__item:last-child .uploaded-file {
    margin-bottom: 0 !important;
  }
  .uploaded-file {
    background: #f3f3f4;
    border-radius: 0.25rem;
    &:hover {
      background: #e3e4e6;
      cursor: pointer;
      .file-name {
        text-decoration: underline;
      }
    }
    .file-name {
      width: 20rem;
    }
    .upload-loading {
      display: flex;
      width: 16px;
      height: 16px;
      align-items: end;
      .el-loading-spinner .circular {
        height: 16px;
        width: 16px;
      }
    }
    .remove-file-icon:hover {
      background: unset;
    }
  }
  .is-in-review-box {
    border: 1px solid #e3e4e6;
    border-radius: 0.25rem;
    padding: 1rem 1rem 0 1rem;
    margin-bottom: 1rem;
  }
  .attachment {
    width: fit-content;
    padding: 0.25rem 0.375rem 0.25rem 0.5rem;
    border-radius: 4px;
    border: 1px solid #e3e4e6;
    cursor: pointer;
  }
}
</style>
