<template>
  <div>
    <div class="mb-4 table-filters">
      <SearchableDropdown
        v-show="false"
        :selected-item="selectedCustomerFilter"
        :options="businesses"
        :label="$t('requestsSupplier.table.filters.customer')"
        :search-placeholder="$t('commons.searchWithDots')"
        @change="onCustomerFilterChanged"
      />
      <DropdownTableFilter
        :filter-name="$t('requestsSupplier.table.filters.requestType')"
        :filter-value="requestTypeFilterText"
        trigger="click"
        :clearable="true"
        @on-choose-item="onRequestTypeFilterChanged"
        @on-clear-filter="onRequestTypeFilterChanged(null)"
      >
        <div slot="filter-value" class="request-type-filter">
          {{ requestTypeFilterText }}
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :command="requestTypeMapping[REQUEST_TYPES.BALANCE_ALIGNMENT]">
            <div class="request-type-filter">
              {{ $t(`requestsSupplier.table.requestType.${requestTypeMapping[REQUEST_TYPES.BALANCE_ALIGNMENT]}`) }}
            </div>
          </el-dropdown-item>
          <el-dropdown-item :command="requestTypeMapping[REQUEST_TYPES.BALANCE_ALIGNMENT_RECONCILIATION_STATEMENT]">
            <div class="request-type-filter">
              {{
                $t(
                  `requestsSupplier.table.requestType.${
                    requestTypeMapping[REQUEST_TYPES.BALANCE_ALIGNMENT_RECONCILIATION_STATEMENT]
                  }`
                )
              }}
            </div>
          </el-dropdown-item>
          <el-dropdown-item :command="requestTypeMapping[REQUEST_TYPES.BALANCE_ALIGNMENT_MISSING_DOCUMENTS]">
            <div class="request-type-filter">
              {{
                $t(
                  `requestsSupplier.table.requestType.${
                    requestTypeMapping[REQUEST_TYPES.BALANCE_ALIGNMENT_MISSING_DOCUMENTS]
                  }`
                )
              }}
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </DropdownTableFilter>
    </div>
    <Table
      v-loading="requestsLoading"
      custom-class="requests-table"
      :data="tableData"
      :columns="columns"
      :active-sort="activeSort"
      show-index
      rounded
      border
      :hover="actionAllowed"
      @row-click="handleRowClick"
    >
      <template #cell-index="{ rowIndex }">
        {{ rowIndex + 1 + pageLimit * (currentPage - 1) }}
      </template>
      <template #[`cell-${TABLE_HEADERS.REQUEST_STATUS}`]="{ rowData: { requestStatus, rejectionText } }">
        <div class="d-flex">
          <el-tooltip placement="top" :disabled="requestStatus !== REQUEST_STATUS.REVIEW_REQUIRED">
            <Tag :type="REQUEST_STATUS_TAGS[requestStatus]">
              {{ $t(`requestsSupplier.table.requestStatus.${requestStatus}`) }}
            </Tag>
            <div slot="content" class="text-start text-break">
              <p v-for="(line, i) in rejectionText" :key="i">{{ line }}</p>
            </div>
          </el-tooltip>
        </div>
      </template>
      <template #cell-details="{ rowData: { reconciliationDate, id } }">
        <div class="position-relative">
          <div :id="id" class="truncated-text m-10000">
            {{ `${$t('requestsSupplier.table.details.reconciliation')} ${reconciliationDate}` }}
          </div>
        </div>
      </template>
      <template #[`cell-${TABLE_HEADERS.CUSTOMER}`]="{ rowData: { customer } }">
        <div class="customer-field gap-2">
          <div class="customer-icon rounded-pill">
            <img
              v-if="businessesById[customer.id]?.logoUrl"
              :src="`${businessesById[customer.id].logoUrl}&token=${token}`"
              width="24"
              height="24"
            />
            <BusinessIcon v-else class="default-icon" :size="16" />
          </div>
          <TruncatedText class="customer-name flex-fill">{{ customer.name }}</TruncatedText>
        </div>
      </template>
    </Table>
    <div class="stylelessDiv"></div>
    <ResponseModal v-if="pickedRequest" :request="pickedRequest" @close="pickedRequest = null" />
  </div>
</template>

<script>
import { ref, computed, getCurrentInstance } from 'vue';
import { indexBy, prop } from 'ramda';
import { DateTime } from 'luxon';

import { Table, TruncatedText, Tag, DropdownTableFilter } from '@/modules/core';
import { SearchableDropdown } from '@/modules/document/components/table-filters';
import { useTenancy } from '@/modules/auth';
import { BusinessIcon } from '@/assets/icons';

import { useBusinesses } from '../compositions';
import ResponseModal from './ResponseModal';
import { REQUEST_TYPES } from '../../constants';

const TABLE_HEADERS = {
  REQUEST_STATUS: 'requestStatus',
  OPENED_BEFORE: 'openedBefore',
  CLOSED_BEFORE: 'closedBefore',
  RESPONDED_BEFORE: 'respondedBefore',
  CUSTOMER: 'customer',
  REQUEST_TYPE: 'template',
  DETAILS: 'details',
};

const REQUEST_STATUS = {
  OPEN: 'open',
  IN_REVIEW: 'inReview',
  CLOSED: 'closed',
  REVIEW_REQUIRED: 'reviewRequired',
};

const REQUEST_STATUS_TAGS = {
  [REQUEST_STATUS.OPEN]: 'neutral',
  [REQUEST_STATUS.IN_REVIEW]: 'info',
  [REQUEST_STATUS.CLOSED]: 'neutral',
  [REQUEST_STATUS.REVIEW_REQUIRED]: 'danger',
};

export default {
  components: {
    Table,
    TruncatedText,
    Tag,
    DropdownTableFilter,
    SearchableDropdown,
    BusinessIcon,
    ResponseModal,
  },
  props: {
    requestsLoading: { type: Boolean, default: false },
    requests: { type: Array, required: true },
    currentPage: { type: Number, required: true },
    pageLimit: { type: Number, required: true },
    actionAllowed: { type: Boolean, default: false },
  },
  setup(props) {
    const { token } = useTenancy();
    const root = getCurrentInstance().proxy;
    const requestTypeFilter = computed({
      get: () => root.$route.query.requestType,
      set: (value) =>
        root.$router.replace({ ...root.$route, query: { ...root.$route.query, requestType: value || undefined } }),
    });

    const requestTypeName = ref(null);
    const customerFilter = computed({
      get: () => root.$route.query.customer,
      set: (value) =>
        root.$router.replace({ ...root.$route, query: { ...root.$route.query, customer: value || undefined } }),
    });

    const { businesses } = useBusinesses('restaurants');

    const businessesById = computed(() => indexBy(prop('id'), businesses.value));

    const requestTypeMapping = {
      [REQUEST_TYPES.BALANCE_ALIGNMENT]: 'RS_ADR',
      [REQUEST_TYPES.BALANCE_ALIGNMENT_MISSING_DOCUMENTS]: 'missingInvoices',
      [REQUEST_TYPES.BALANCE_ALIGNMENT_RECONCILIATION_STATEMENT]: 'RS_ADR_cInvoice',
    };

    const isClosedRequests = computed(() => props.requests[0]?.closed);
    const isInReviewRequests = computed(() => !props.requests[0]?.isAwaitingResponse && !props.requests[0]?.closed);

    return {
      TABLE_HEADERS,
      REQUEST_TYPES,
      REQUEST_STATUS,
      requestTypeFilter,
      requestTypeName,
      customerFilter,
      businessesById,
      requestTypeMapping,
      businesses,
      token,
      activeSort: ref({
        direction: -1,
        columnKey: computed(() =>
          isClosedRequests.value
            ? TABLE_HEADERS.CLOSED_BEFORE
            : isInReviewRequests.value
            ? TABLE_HEADERS.RESPONDED_BEFORE
            : TABLE_HEADERS.OPENED_BEFORE
        ),
      }),
      root,
      pickedRequest: ref(null),
      isClosedRequests,
      isInReviewRequests,
      REQUEST_STATUS_TAGS,
    };
  },
  computed: {
    requestTypeFilterText() {
      const selectedType = this.requestTypeName;
      return selectedType ? this.$t(`requestsSupplier.table.requestType.${selectedType}`) : '';
    },
    selectedCustomerFilter() {
      return this.businessesById[this.customerFilter];
    },

    columns() {
      return [
        {
          header: this.$t(`requestsSupplier.table.headers.${TABLE_HEADERS.REQUEST_STATUS}`),
          key: TABLE_HEADERS.REQUEST_STATUS,
          minWidth: '25%',
        },
        {
          header: this.$t(
            `requestsSupplier.table.headers.${
              this.isClosedRequests
                ? TABLE_HEADERS.CLOSED_BEFORE
                : this.isInReviewRequests
                ? TABLE_HEADERS.RESPONDED_BEFORE
                : TABLE_HEADERS.OPENED_BEFORE
            }`
          ),
          key: this.isClosedRequests
            ? TABLE_HEADERS.CLOSED_BEFORE
            : this.isInReviewRequests
            ? TABLE_HEADERS.RESPONDED_BEFORE
            : TABLE_HEADERS.OPENED_BEFORE,
          minWidth: '18%',
          sortCallback: (direction) => {
            this.activeSort = {
              direction,
              columnKey: this.isClosedRequests
                ? TABLE_HEADERS.CLOSED_BEFORE
                : this.isInReviewRequests
                ? TABLE_HEADERS.RESPONDED_BEFORE
                : TABLE_HEADERS.OPENED_BEFORE,
            };
            this.root.$router.replace({
              ...this.root.$route,
              query: {
                ...this.root.$route.query,
                orderBy: direction,
                sortBy: this.isClosedRequests
                  ? 'closedAt'
                  : this.isInReviewRequests
                  ? 'responseCreatedAt'
                  : 'createdAt',
              },
            });
          },
        },
        {
          header: this.$t(`requestsSupplier.table.headers.${TABLE_HEADERS.CUSTOMER}`),
          key: TABLE_HEADERS.CUSTOMER,
          minWidth: '18%',
        },
        {
          header: this.$t(`requestsSupplier.table.headers.${TABLE_HEADERS.REQUEST_TYPE}`),
          key: TABLE_HEADERS.REQUEST_TYPE,
          minWidth: '18%',
          sortCallback: (direction) => {
            this.activeSort = { direction, columnKey: TABLE_HEADERS.REQUEST_TYPE };
            this.root.$router.replace({
              ...this.root.$route,
              query: { ...this.root.$route.query, orderBy: direction, sortBy: TABLE_HEADERS.REQUEST_TYPE },
            });
          },
        },
        {
          header: this.$t(`requestsSupplier.table.headers.${TABLE_HEADERS.DETAILS}`),
          key: TABLE_HEADERS.DETAILS,
          minWidth: '18%',
        },
      ];
    },
    tableData() {
      return this.requests.map((request) => {
        const {
          details,
          sourceBusiness,
          createdAt,
          closed,
          template,
          id,
          templateData,
          closedAt,
          isAwaitingResponse,
          responses,
        } = request;
        const formattedDetails = details?.split('\n');

        const reconciliationDate =
          templateData && template === 'balanceAlignmentMissingDocuments'
            ? templateData[template]?.documents[0].docDate
            : templateData[template]?.reconciliationPeriodEnd;

        const type = this.requestTypeMapping[template];
        const lastResponse = responses?.at(-1) ?? null;

        return {
          id,
          requestStatus: closed
            ? REQUEST_STATUS.CLOSED
            : !isAwaitingResponse
            ? REQUEST_STATUS.IN_REVIEW
            : lastResponse?.reject
            ? REQUEST_STATUS.REVIEW_REQUIRED
            : REQUEST_STATUS.OPEN,
          openedBefore: this.getTimeAgo(createdAt),
          customer: sourceBusiness,
          template: this.$t(`requestsSupplier.table.requestType.${type}`),
          details: formattedDetails,
          reconciliationDate: this.formatReconciliationDate(reconciliationDate),
          ...(closed && { closedBefore: this.getTimeAgo(closedAt) }),
          ...(!isAwaitingResponse &&
            !closed &&
            lastResponse && { respondedBefore: this.getTimeAgo(lastResponse.createdAt) }),
          ...(lastResponse?.reject && { rejectionText: lastResponse.reject.text.split('\n') }),
        };
      });
    },
  },
  methods: {
    actionsVisibleChange(index, isVisible) {
      this.activeRowActionsIndex = isVisible ? index : -1;
    },
    handleRequestUpdate(updateData) {
      this.$emit('update-request', updateData);
    },
    isExpandable(id, requestComment) {
      const div = document.querySelector('.stylelessDiv');
      const element = document.createElement('div');
      element.id = `temporary-${id}`;
      element.style.width = '31rem';
      element.style.overflowWrap = 'break-word';
      element.textContent = requestComment;
      div.appendChild(element);
      const temporary = document.getElementById(`temporary-${id}`);
      const clientHeight = temporary.clientHeight;
      div.removeChild(element);
      return clientHeight > 24;
    },
    onRequestTypeFilterChanged(command) {
      const templateName = Object.keys(this.requestTypeMapping).find((key) => this.requestTypeMapping[key] === command);
      this.requestTypeName = command;
      this.requestTypeFilter = templateName ?? null;
    },
    onCustomerFilterChanged(selectedCustomer) {
      this.customerFilter = selectedCustomer ? selectedCustomer.id : null;
    },
    getTimeAgo(ms) {
      return DateTime.fromMillis(ms).toRelative();
    },
    getLastResponseTime(responses) {
      const relevantTime = new Date(responses[responses.length - 1].createdAt);
      return DateTime.fromJSDate(relevantTime).toRelative({ base: DateTime.now() });
    },
    formatReconciliationDate(reconciliationDate) {
      if (!reconciliationDate) return '-';

      const date = new Date(reconciliationDate);
      return date.toLocaleDateString(this.$i18n.locale, {
        month: 'short',
        year: '2-digit',
        timeZone: 'UTC',
      });
    },
    handleRowClick(event) {
      if (!this.actionAllowed) return;
      this.pickedRequest = this.requests[event];
    },
    statusHeaderName() {
      return this.tableData[0] && this.tableData[0].requestStatus === REQUEST_STATUS.IN_REVIEW
        ? this.$t(`requestsSupplier.table.headers.${TABLE_HEADERS.TRANSFERRED_FOR_REVIEW}`)
        : this.$t(`requestsSupplier.table.headers.${TABLE_HEADERS.OPENED_BEFORE}`);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/stylesheets/scss/global';
::v-deep {
  .el-select-dropdown.el-popper {
    width: 250px;
  }
  .table-responsive {
    overflow: unset;
  }
}

.table-filters {
  display: flex;
  gap: 0.5rem;
}

.requests-table {
  .actions-btn {
    &.active {
      visibility: visible;
    }
  }
}

tr {
  .actions-btn {
    visibility: hidden;
  }

  &:hover .actions-btn {
    visibility: visible;
  }
}

.truncated-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  word-break: break-word;
}

.expandable:hover {
  -webkit-line-clamp: unset;
  line-clamp: unset;
  display: inline-block;
  padding: 0.5rem;
  background: #fff;
  position: absolute;
  border-radius: 6px;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  z-index: 1;
  width: 31rem;
  margin-top: -1rem;
}

.customer-field {
  display: flex;
  align-items: center;

  .customer-icon {
    background-color: #fbfbfb;
    border: 1px solid #d9dcde;
  }
}
</style>
